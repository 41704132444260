import React, { useState } from 'react';
import './App.scss';
import dog from "./imgs/dog.gif";
import kiss from "./imgs/kiss.gif";

function App() {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [displayHide, setDisplayHide] = useState(false);
  const [noCounter, setNoCounter] = useState(0);
  const [displayYes, setDisplayYes] = useState(false);

  const noMove = () => {
    setPosition((prevPosition) => ({
      x: prevPosition.x + Math.random() * (300 - -300) + -300, // Adjust the values according to your desired position change
      y: prevPosition.y + Math.random() * (200 - -200) + -200,
    }));

    if(noCounter === 4) {
      setDisplayHide(true);
    } else {
      setNoCounter(noCounter + 1);
    }

  }

  const yesDisplay = () => {
    setDisplayYes(true);
  }

  return (
    <div className="App">
      <div className={`App__container ${displayYes ? 'hidden' : ''}`}>
        <h1 className={`App__container-title ${displayHide ? 'hidden' : ''}`}>Will You be my Homietine? You need to feel loved too.</h1>
        <h1 className={`App__container-text ${displayHide ? 'hidden' : ''}`}>Unlike Reese I won't make you eat hotpot.</h1>
        <h1 className={`App__container-please ${displayHide ? 'hidden' : ''}`}>Bitch. Stop hitting no already</h1>
        <img alt="dog gif" className='App__container-dog' src={dog}/>
        <div className='App__container__question'>
          <button className='App__container__question-yes' onClick={yesDisplay}>Yes</button>
          <button style={{ transform: `translate(${position.x}px, ${position.y}px)` }} className='App__container__question-no' onClick={noMove} >No</button>
        </div>
      </div>

      <div className={`App__yes-container ${displayYes ? 'hidden' : ''}`}>
        <h1 className='App__yes-container__title'>Hell Yeah Brother. You deserve some love too</h1>
        <img alt="kissing homies good night gif" className='App__yes-container__kiss' src={kiss}/>
      </div>
    </div>
  );
}

export default App;
